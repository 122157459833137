.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  padding: 20px;
}

.logo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  padding: 3px;
  border: 2px solid green;
}

.info {
  margin: 0px;
  padding: 0px;
}

.info > li {
  list-style-type: none;
  height: 25px;
}

.buttons {
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.buttons > a {
  height: 50px;
  background-color: white;
  display: flex;
  align-items: center;
  filter: drop-shadow(0px 2px 4px rgba(24, 39, 75, 0.08)) drop-shadow(0px 4px 4px rgba(24, 39, 75, 0.04));
  border-radius: 10px;
  text-decoration: none
}

.buttons > a > span {
  flex-grow: 2;
  text-align: center;
}